<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'PaySystem', title: 'Налаштування платіжних систем' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_pay_system)"
        :modal="show_pay_system_dialog"
        :nonClickable="false"
        @updateItemModal="paySystemUpdateItemModal"
    />

    <MainModal
        :main="{ component: 'BankAccount', title: 'Банківські рахунки' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_bank_account)"
        :modal="show_bank_account_dialog"
        :nonClickable="false"
        @updateItemModal="bankAccountUpdateItemModal"
    />
    <v-row>
      <v-col cols="12" md="12">
        <v-card-text class="pa-0">
          <v-tabs class="custom-tabs" color="success">
            <v-tab class="text-left justify-start">
              Реквізити
            </v-tab>
            <v-tab class="text-left justify-start">
              Банківські рахунки
            </v-tab>
            <v-tab class="text-left justify-start">
              Документи
            </v-tab>
            <v-tab class="text-left justify-start">
              Фізичні особи
            </v-tab>
            <v-tab class="text-left justify-start">
              Відповідальні
            </v-tab>
            <v-tab class="text-left justify-start">
              Контакти
            </v-tab>
            <v-tab>
              QR код
            </v-tab>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card tile elevation="1" class="fill-height" :class="bordered ? 'bordered-card' : '' ">
                    <v-card-text>
                      <v-tabs class="custom-tabs-1" color="success">
                        <v-tab class="text-left justify-start">
                          Основна інформація
                        </v-tab>
                        <v-tab class="text-left justify-start">
                          Платежі (особистий кабінет)
                        </v-tab>

                        <v-tab-item class="pa-0 pt-4">
                          <v-form v-model="formValid">
                            <v-row class="dense-wrapper pt-2">
                              <v-col cols="12" v-if="disable_fetch && !itemId">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Email"
                                    v-model="email"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="email ? '' : 'req-star'"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" class="d-flex">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Повна назва"
                                    v-model="full_name"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="full_name ? '' : 'req-star'"
                                    color="grey"
                                    style="flex: 1"
                                />
                                <v-checkbox v-if="disable_fetch"
                                            v-model="disable"
                                            hide-details
                                            color="success"
                                            class="ml-2"
                                            :label="disable ? 'Відключено' : 'Не відключено'"
                                            style="flex: 0 0 160px"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Коротка назва"
                                    v-model="short_name"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="short_name ? '' : 'req-star'"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                    type="text"
                                    :items="tax_groups"
                                    hide-details
                                    filled label="Тип оподаткування"
                                    v-model="tax_group"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="tax_group ? '' : 'req-star'"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Код ЄДРПОУ"
                                    v-model="code"
                                    required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                    :class="code ? '' : 'req-star'"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="ІПН"
                                    v-model="ipn"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Номер свідоцтва ПДВ"
                                    v-model="pdv_ipn"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12">
                                <ACC_TaxInspection :value="tax_inspection_id"
                                                   @autocompleteChange="onTaxInspectionChange"/>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-textarea
                                    type="text"
                                    hide-details
                                    filled label="Юридична адреса"
                                    rows="2"
                                    v-model="address_yur"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-textarea
                                    type="text"
                                    hide-details
                                    filled label="Фактична адреса"
                                    rows="2"
                                    v-model="address_fact"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="Код організації по EPS"
                                    v-model.number="eps_id"
                                    color="grey"
                                />
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                    type="text"
                                    hide-details
                                    filled label="ID сайту"
                                    v-model="slug"
                                    color="grey"
                                    disabled
                                />
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-checkbox v-model="accounting"
                                            hide-details
                                            color="success"
                                            class="ml-2 mt-1"
                                            :disabled="!isSuperUser"
                                            :label="accounting ? 'Модуль бух.облік (включено)' : 'Модуль бух.облік (відключено)'"
                                />
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-checkbox v-model="legacy"
                                            hide-details
                                            color="success"
                                            class="ml-2 mt-1"
                                            :disabled="!isSuperUser"
                                            @change="changeLegacy"
                                            :label="legacy ? 'Модуль юридичних осіб (включено)' : 'Модуль юридичних осіб (відключено)'"
                                />
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-checkbox v-model="dispatcher"
                                            hide-details
                                            color="success"
                                            :disabled="!isSuperUser"
                                            class="ml-2 mt-1"
                                            :label="dispatcher ? 'Модуль диспетчерської (включено)' : 'Модуль диспетчерської (відключено)'"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions class="px-0 mt-4">
                            <v-btn large depressed block color="success" @click.stop="updateOrganization"
                                   :loading="getModalLoading">
                              <v-icon left>mdi-content-save</v-icon>
                              Записати
                            </v-btn>
                          </v-card-actions>
                        </v-tab-item>
                        <v-tab-item class="pa-0 pt-4">
                          <v-row class="dense-wrapper pt-2">
                            <v-col cols="12">
                              <v-toolbar elevation="0" class="pa-0">
                                <v-toolbar-title>
                                  Налаштування платіжних систем
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom color="success">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="openPaySystemCreateDialog"
                                           v-bind="attrs"
                                           v-on="on"
                                           class="grey lighten-4 mr-1">
                                      <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Створити нове налаштування платіжних систем</span>
                                </v-tooltip>
                              </v-toolbar>
                              <v-data-table
                                  :headers="paySystemHeader"
                                  :items="pay_system_items"
                                  hide-default-footer
                                  class="custom-table custom-table-1"
                                  @click:row="onPaySystemItemClick"
                                  dense
                              >
                                <template v-slot:item.row_icon>
                                  <v-icon size="26" class="mr-2" color="success">
                                    mdi-account-credit-card
                                  </v-icon>
                                </template>
                                <template v-slot:item.enable="{ item }">
                                  <v-simple-checkbox :ripple="false" :value="item.enable" color="grey darken-1"/>
                                </template>
                                <template v-slot:item.data="{ item }">
                                  <v-textarea
                                      style="text-align: left"
                                      :value="JSON.stringify(item.data, null, '\t').trim()"
                                      auto-grow
                                      rows="2"
                                      hide-details
                                      flat
                                      readonly
                                      disabled
                                      solo
                                      dense
                                  />
                                </template>
                                <template v-slot:item.row_type="{ item }">
                                  {{ getPaySystemRowType(item.row_type) }}
                                </template>
                                <template v-slot:item.template_type="{ item }">
                                  {{ getPaySystemTemplateType(item.template_type) }}
                                </template>
                                <template v-slot:item.icon="{ item }">
                                  <template v-if="item.icon === 'google_pay'">
                                    <img :src="`${publicPath}img/icons/google_pay.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'ipay'">
                                    <img :src="`${publicPath}img/icons/ipay_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'privat24'">
                                    <img :src="`${publicPath}img/icons/privat24_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'easy_pay'">
                                    <img :src="`${publicPath}img/icons/easy_pay_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'mastercard'">
                                    <img :src="`${publicPath}img/icons/mastercard_logo.svg`" alt="google_pay"
                                         height="40" width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'visa'">
                                    <img :src="`${publicPath}img/icons/visa_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'mono'">
                                    <img :src="`${publicPath}img/icons/mono_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else-if="item.icon === 'apple_pay'">
                                    <img :src="`${publicPath}img/icons/Apple_Pay_logo.svg`" alt="google_pay" height="40"
                                         width="40">
                                  </template>
                                  <template v-else>
                                    <v-icon size="24">
                                      {{ item.icon }}
                                    </v-icon>
                                  </template>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-card tile elevation="1" class="fill-height" :class="bordered ? 'bordered-card' : '' ">
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Пошук"
                          placeholder="Почніть вводити текст для пошуку"
                          single-line
                          hide-details
                          class="px-4 mb-3"
                      />
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openBankAccountCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити новий банківський рахунок</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                          :headers="bankAccountsHeaders"
                          :items="bankAccounts"
                          :search="search"
                          :items-per-page="10"
                          :footer-props="{
                                          showFirstLastPage: true,
                                          itemsPerPageText: 'Рядків на сторінку',
                                          itemsPerPageOptions: [10, 15, 30, -1],

                                        }"
                          @click:row="onBankAccountItemClick"
                          class="custom-table"
                      >
                        <template v-slot:item.icon>
                          <v-icon size="26" class="mr-2" color="success">
                            mdi-bank
                          </v-icon>
                        </template>
                        <template v-slot:item.main="{ item }">
                          <v-simple-checkbox color="grey" :value="item.main === null ? false : item.main"
                                             :disabled="true"/>
                        </template>
                        <template v-slot:item.account_type="{ item }">
                          {{ bankAccountTypes[item.account_type] || 'Розрахунковий' }}
                        </template>
                        <template v-slot:item.disable="{ item }">
                          <v-simple-checkbox color="grey" :value="item.disable === null ? false : item.disable"
                                             :disabled="true"/>
                        </template>
                        <template v-slot:item.service_name="{ item }">
                          <span>{{ item.service_name }}</span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <OrganizationDocuments/>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <NaturalPerson/>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <ResponsePersonView/>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <OrganizationContactView/>
            </v-tab-item>
            <v-tab-item class="pa-0">
              <v-row class="pa-2">
                <v-col cols="12" md="4">
                  <v-card rounded min-height="304">
                    <v-card-title>
                      QR код для рахунків
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="5">
                          <div>
                            <v-btn depressed class="grey lighten-3 justify-start" left block
                                   @click="handleFileImport"
                            >
                              <v-icon class="mr-5" color="success">
                                mdi-file-image-plus-outline
                              </v-icon>
                              Обрати файл
                            </v-btn>
                            <v-btn depressed class="grey lighten-3 mt-3 justify-start" left block
                                   @click="clearQrCode"
                            >
                              <v-icon class="mr-5" color="secondary">
                                mdi-file-image-remove-outline
                              </v-icon>
                              Очистити файл
                            </v-btn>
                            <v-btn depressed class="grey lighten-3 mt-3 justify-start" left block
                                   @click="removeQrCode"
                            >
                              <v-icon class="mr-5" color="error">
                                mdi-file-image-minus-outline
                              </v-icon>
                              Видалити QR
                            </v-btn>
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                @change="onImageChange"
                            >
                          </div>
                        </v-col>
                        <v-col cols="12" md="7">
                          <div style="height: 200px; border: 1px solid #5a5a5a; border-radius: 5px;"
                               class="d-flex align-center justify-center"
                          >
                            <img id="previewImage" alt="Preview" ref='imagePreview' v-if="file_qr"
                                 style="width: 100%; height: 100%; object-fit: contain;" src="#">
                            <template v-else>
                              <img id="previewImage" alt="Preview" ref='imagePreview' v-if="qr_url"
                                   :src="`${BASE_URL_AXIOS}/${qr_url}`"
                                   style="width: 100%; height: 100%; object-fit: contain;">
                              <v-icon size="140" v-else>
                                mdi-file-image-outline
                              </v-icon>
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card rounded min-height="304">
                    <v-card-title>
                      Формування EPS QR code
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              v-model="secret_data.left"
                              hide-details
                              :class="secret_data.left ? '' : 'req-star'"
                              filled
                              label="Ліва частина"
                              placeholder="EK_multiplechoice_"
                              color="grey"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                              v-model="secret_data.right"
                              hide-details
                              placeholder="_1092355"
                              label="Права частина"
                              :class="secret_data.right ? '' : 'req-star'"
                              filled
                              color="grey"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-btn block depressed class="mb-2"
                                 @click="fillSecretsData" :loading="secrets_loading">Заповнити (ЕК для Тернополя)</v-btn>
                          <v-btn block depressed
                                 @click="generateSecrets" :loading="secrets_loading">Сформувати QR по абонентах</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapActions, mapGetters} from "vuex";
import {CREATE_ORGANIZATION, GET_ORGANIZATION, UPDATE_ORGANIZATION} from "@/store/actions/organization";
import {USER_SET_CURRENT_ORGANIZATION} from "@/store/actions/user";
import {GET_BANK_ACCOUNT} from "@/store/actions/bank";
import {bankAccountTypes, getAccountIcon, getPaySystemTemplateType, getPaySystemRowType} from '@/utils/icons'
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";
import {GET_ALL_PAY_SYSTEM} from "@/store/actions/pay_system";
import organizationAPI from "@/utils/axios/organization"
import remoteFunctions from "@/utils/axios/eps"
import {BASE_URL_AXIOS} from "@/utils/axios";

export default {
  name: "Organization",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    bordered: {
      type: Boolean,
      default: false
    },
    disable_fetch: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainModal,
    ResponsePersonView: () => import("@/components/ResponsePersonView"),
    OrganizationContactView: () => import("@/components/OrganizationContactView"),
    NaturalPerson: () => import("@/components/NaturalPersonView"),
    OrganizationDocuments: () => import("@/components/OrganizationDocumentsNew"),
    ACC_TaxInspection: () => import("@/components/accounting/autocomplite/modal/ACC_TaxInspection.vue")
  },
  computed: {
    ...mapGetters({
      bankAccounts: 'getBankAccountsByOrganization',
      isSuperUser: 'isSuperUser',
      getModalLoading: 'get_modal_loading',
      pay_system_items: 'getPaySystems'
    })
  },
  data() {
    return {
      bankAccountTypes,
      search: '',
      search_responsible: '',
      formValid: false,
      accounting: false,
      legacy: false,
      dispatcher: false,
      full_name: null,
      short_name: null,
      email: null,
      code: null,
      ipn: null,
      pdv_ipn: null,
      address_fact: null,
      address_yur: null,
      tax_group: null,
      disable: false,
      eps_id: null,
      eps_pay: false,
      eps_pay_id: "",
      privat_pay: false,
      privat_pay_id: "",
      tax_inspection_id: null,
      qr_url: null,
      paySystemHeader: [
        {text: '', value: 'row_icon', width: 60},
        {text: 'Вкл.', value: 'enable', width: 80},
        {text: 'Вид запису', value: 'row_type', width: 120},
        {text: 'Тип шаблону', value: 'template_type', width: 120},
        {text: 'Шаблон URL', value: 'url_template',},
        {text: 'Дані', value: 'data', width: 400},
        {text: 'Назва', value: 'button_name', width: 200},
        {text: 'Іконка', value: 'icon', width: 100},
      ],
      bankAccountsHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Основ.', value: 'main', width: 100},
        {text: 'Відкл.', value: 'disable', width: 100},
        {text: 'Вид рахунка', value: 'account_type'},
        {text: 'Банк', value: 'bank_name'},
        {text: 'МФО', value: 'bank_mfo'},
        {text: '№ рахунка', value: 'account'},
        {text: 'Послуга', value: 'service_name'}
      ],
      responsePersonsHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Категорія', value: 'category'},
        {text: 'Відповідальний', value: 'response_name'},
        {text: 'Посада', value: 'position'},
        {text: 'Початок', value: 'date_start'},
        {text: 'Закінчення', value: 'date_end'},
      ],
      responsePersonsData: [],
      tax_groups: [
        {text: 'Єдиниий податок', value: 'single_tax'},
        {text: 'Загальна система', value: 'general_tax'},
        {text: 'Неприбуткова організація', value: 'non_profit'},
      ],
      bank_account_dialog_id: 0,
      itemId: null,
      slug: '',
      file_qr: null,
      publicPath: process.env.BASE_URL,
      BASE_URL_AXIOS,
      secrets_loading: false,
      secret_data: {
        left: '',
        right: ''
      },
      selected_pay_system: {},
      show_pay_system_dialog: false,
      selected_bank_account: {},
      show_bank_account_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchOrganization: GET_ORGANIZATION,
      fetchBankAccounts: GET_BANK_ACCOUNT,
      fetchPaySystems: GET_ALL_PAY_SYSTEM
    }),
    handleFileImport() {
      this.$refs.uploader.click();
    },
    onImageChange(e) {
      this.file_qr = e.target.files[0];

      this.$nextTick(() => {
        const imagePreview = this.$refs.imagePreview
        if (this.file_qr) {
          const reader = new FileReader();
          reader.readAsDataURL(this.file_qr);
          reader.onload = function (o) {
            imagePreview.src = o.target.result;
          }
        }
      })

    },
    getPaySystemTemplateType,
    getPaySystemRowType,
    getAccountIcon,
    onTaxInspectionChange(payload) {
      this.tax_inspection_id = payload.value || null
    },
    changeLegacy(payload) {
      if (payload) {
        this.accounting = true
      }
    },
    getBasePath() {
      return window.location.origin
    },
    crud_qr_code() {
      if (this.qr_url && !this.file_qr) {
        return
      }

      const qr_payload = new FormData()
      qr_payload.append('file', this.file_qr)

      organizationAPI.create_qr_code(qr_payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.qr_url = `${this.publicPath}${data.file_path}`
            } else {
              this.qr_url = null
            }
          })
          .finally(() => {
            this.this.file_qr = null
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    clearQrCode() {
      this.file_qr = null
    },
    removeQrCode() {
      this.file_qr = null
      this.qr_url = null
    },
    updateOrganization() {
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Заповніть усі обов’язкові поля',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        full_name: this.full_name,
        short_name: this.short_name,
        code: this.code,
        ipn: this.ipn,
        pdv_ipn: this.pdv_ipn,
        address_fact: this.address_fact,
        address_yur: this.address_yur,
        tax_group: this.tax_group,
        disable: this.disable,
        eps_id: this.eps_id || null,
        slug: this.slug || null,
        eps_pay: this.eps_pay || false,
        eps_pay_id: this.eps_pay_id,
        privat_pay: this.privat_pay || false,
        privat_pay_id: this.privat_pay_id,
        accounting: this.accounting || false,
        tax_inspection_id: this.tax_inspection_id || null,
        legacy: this.legacy || false,
        dispatcher: this.dispatcher || false
      }

      if (!this.disable_fetch) {
        this.$store.commit(START_MODAL_LOADING)
        this.$store.dispatch(UPDATE_ORGANIZATION, payload)
            .then(res => {
              if (!this.disable_fetch) {
                this.$store.commit(USER_SET_CURRENT_ORGANIZATION, res)
              } else {
                this.$emit('update_item', res)
              }
              this.crud_qr_code()
              this.$store.commit(ALERT_SHOW, {text: 'Дані організації оновлено успішно', color: 'success'})
            })
            .finally(() => {
              this.$store.commit(STOP_MODAL_LOADING)
            })
      } else {
        if (this.itemId === null || this.itemId === undefined) {
          payload.email = this.email
          payload.user_url = this.getBasePath()

          this.$store.commit(START_MODAL_LOADING)
          this.$store.dispatch(CREATE_ORGANIZATION, payload)
              .then(res => {
                if (!this.disable_fetch) {
                  this.$store.commit(USER_SET_CURRENT_ORGANIZATION, res)
                } else {
                  this.$emit('update_item', res)
                }
                this.crud_qr_code()
                this.$store.commit(ALERT_SHOW, {text: 'Організацію створено успішно', color: 'success'})
              })
              .finally(() => {
                this.$store.commit(STOP_MODAL_LOADING)
              })
        } else {
          payload.id = this.itemId
          this.$store.commit(START_MODAL_LOADING)
          this.$store.dispatch(UPDATE_ORGANIZATION, payload)
              .then(res => {
                if (!this.disable_fetch) {
                  this.$store.commit(USER_SET_CURRENT_ORGANIZATION, res)
                } else {
                  this.$emit('update_item', res)
                }
                this.crud_qr_code()
                this.$store.dispatch(ALERT_SHOW, {text: 'Дані організації оновлено успішно', color: 'success'})
              })
              .finally(() => {
                this.$store.commit(STOP_MODAL_LOADING)
              })
        }
      }
    },
    generateSecrets() {
      if (this.itemId) return

      if (!this.secret_data.left || !this.secret_data.right) {
        this.$store.commit(ALERT_SHOW, {text: 'Ліва та права частини для формування qr кодів мають бути заповнені', color: 'error'})
        return;
      }

      this.secrets_loading = true
      remoteFunctions.generate_secrets(this.secret_data.left, this.secret_data.right)
          .then(() => {
            this.$store.commit(ALERT_SHOW, {text: 'Формування qr кодів - завершено', color: 'success'})
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.secrets_loading = false
          })

    },
    fillSecretsData() {
      this.secret_data.left = 'EK_multiplechoice_'
      this.secret_data.right = '_1092355'
    },

    paySystemUpdateItemModal() {
      this.show_pay_system_dialog = false
      this.selected_pay_system = {}
    },
    openPaySystemCreateDialog() {
      this.selected_pay_system = {}
      this.show_pay_system_dialog = true
    },
    onPaySystemItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.data = JSON.stringify(local_payload.data)

      this.selected_pay_system = local_payload
      this.show_pay_system_dialog = true
    },

    bankAccountUpdateItemModal() {
      this.show_bank_account_dialog = false
      this.selected_bank_account = {}
    },
    openBankAccountCreateDialog() {
      this.selected_bank_account = {
        is_organization_service: true,
        is_service_service: true
      }
      this.show_bank_account_dialog = true
    },
    onBankAccountItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.is_organization_service = true
      local_payload.is_service_service = true

      this.selected_bank_account = local_payload
      this.show_bank_account_dialog = true
    },
  },
  created() {
    if (!this.disable_fetch) {
      this.fetchOrganization()
          .then(res => {
            if (res) {
              this.full_name = res.full_name
              this.short_name = res.short_name
              this.code = res.code
              this.ipn = res.ipn
              this.pdv_ipn = res.pdv_ipn
              this.address_fact = res.address_fact
              this.address_yur = res.address_yur
              this.tax_group = res.tax_group
              this.disable = res.disable
              this.eps_id = res.eps_id
              this.slug = res.slug
              this.eps_pay = res.eps_pay
              this.eps_pay_id = res.eps_pay_id
              this.privat_pay = res.privat_pay
              this.privat_pay_id = res.privat_pay_id
              this.accounting = res.accounting
              this.legacy = res.legacy
              this.tax_inspection_id = res.tax_inspection_id
              this.qr_url = res.qr_url
              this.dispatcher = res.dispatcher

              this.fetchBankAccounts()
              this.fetchPaySystems()
            }
          })
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.full_name = payload.full_name
          this.short_name = payload.short_name
          this.code = payload.code
          this.ipn = payload.ipn
          this.pdv_ipn = payload.pdv_ipn
          this.address_fact = payload.address_fact
          this.address_yur = payload.address_yur
          this.tax_group = payload.tax_group
          this.itemId = payload.id
          this.slug = payload.slug
          this.slug = payload.slug
          this.eps_pay = payload.eps_pay
          this.eps_pay_id = payload.eps_pay_id
          this.privat_pay = payload.privat_pay
          this.privat_pay_id = payload.privat_pay_id
          this.accounting = payload.accounting
          this.dispatcher = payload.dispatcher
          this.tax_inspection_id = payload.tax_inspection_id
          this.legacy = payload.legacy
          this.qr_url = payload.qr_url

          if (!payload.new) {
            this.fetchBankAccounts(payload.id)
            this.fetchPaySystems()
          } else {
            this.$store.state.bank_accounts = []
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }

  .bordered-card {
    border-left: 3px solid #5bb55f
  }
}

.custom-tabs-1 {
  :deep(div[role="tablist"]) {
    background-color: transparent !important;
  }

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent !important;
  }
}
</style>